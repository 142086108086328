import _reactInputPosition2 from "react-input-position";
import _SideBySideMagnifier2 from "./SideBySideMagnifier";
import _Magnifier2 from "./Magnifier";
import _GlassMagnifier2 from "./GlassMagnifier";
import _PictureInPictureMagnifier2 from "./PictureInPictureMagnifier";
import _MagnifierContainer2 from "./MagnifierContainer";
import _MagnifierPreview2 from "./MagnifierPreview";
import _MagnifierZoom2 from "./MagnifierZoom";
var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "SideBySideMagnifier", {
  enumerable: true,
  get: function get() {
    return _SideBySideMagnifier["default"];
  }
});
Object.defineProperty(exports, "Magnifier", {
  enumerable: true,
  get: function get() {
    return _Magnifier["default"];
  }
});
Object.defineProperty(exports, "GlassMagnifier", {
  enumerable: true,
  get: function get() {
    return _GlassMagnifier["default"];
  }
});
Object.defineProperty(exports, "PictureInPictureMagnifier", {
  enumerable: true,
  get: function get() {
    return _PictureInPictureMagnifier["default"];
  }
});
Object.defineProperty(exports, "MagnifierContainer", {
  enumerable: true,
  get: function get() {
    return _MagnifierContainer["default"];
  }
});
Object.defineProperty(exports, "MagnifierPreview", {
  enumerable: true,
  get: function get() {
    return _MagnifierPreview["default"];
  }
});
Object.defineProperty(exports, "MagnifierZoom", {
  enumerable: true,
  get: function get() {
    return _MagnifierZoom["default"];
  }
});
exports.TOUCH_ACTIVATION = exports.MOUSE_ACTIVATION = void 0;
var _reactInputPosition = _reactInputPosition2;

var _SideBySideMagnifier = _interopRequireDefault(_SideBySideMagnifier2);

var _Magnifier = _interopRequireDefault(_Magnifier2);

var _GlassMagnifier = _interopRequireDefault(_GlassMagnifier2);

var _PictureInPictureMagnifier = _interopRequireDefault(_PictureInPictureMagnifier2);

var _MagnifierContainer = _interopRequireDefault(_MagnifierContainer2);

var _MagnifierPreview = _interopRequireDefault(_MagnifierPreview2);

var _MagnifierZoom = _interopRequireDefault(_MagnifierZoom2);

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

var MOUSE_ACTIVATION = {
  CLICK: _reactInputPosition.MOUSE_ACTIVATION.CLICK,
  DOUBLE_CLICK: _reactInputPosition.MOUSE_ACTIVATION.DOUBLE_CLICK
};
exports.MOUSE_ACTIVATION = MOUSE_ACTIVATION;
var TOUCH_ACTIVATION = {
  TAP: _reactInputPosition.TOUCH_ACTIVATION.TAP,
  DOUBLE_TAP: _reactInputPosition.TOUCH_ACTIVATION.DOUBLE_TAP,
  LONG_TOUCH: _reactInputPosition.TOUCH_ACTIVATION.LONG_TOUCH
};
exports.TOUCH_ACTIVATION = TOUCH_ACTIVATION;
export default exports;
export const __esModule = exports.__esModule,
      SideBySideMagnifier = exports.SideBySideMagnifier,
      Magnifier = exports.Magnifier,
      GlassMagnifier = exports.GlassMagnifier,
      PictureInPictureMagnifier = exports.PictureInPictureMagnifier,
      MagnifierContainer = exports.MagnifierContainer,
      MagnifierPreview = exports.MagnifierPreview,
      MagnifierZoom = exports.MagnifierZoom;
const _TOUCH_ACTIVATION = exports.TOUCH_ACTIVATION,
      _MOUSE_ACTIVATION = exports.MOUSE_ACTIVATION;
export { _TOUCH_ACTIVATION as TOUCH_ACTIVATION, _MOUSE_ACTIVATION as MOUSE_ACTIVATION };